import gql from 'graphql-tag'
import { IMAGE_FRAGMENT } from './imageFragment'
import { VariantFragment } from './variantFragment'

// ModelName - is just a ModelName
// ModelNameConnection - is ModelName + pagination data

export const PRODUCT_FRAGMENT = gql`
  ${VariantFragment}
  fragment ProductFragment on Product {
    id
    handle
    description
    descriptionHtml
    tags
    productType
    sizingHTML: metafield(namespace: "airf", key: "p_sizing") {
      value
    }
    detailsHTML: metafield(namespace: "airf", key: "p_details") {
      value
    }
    stylesWith: metafield(namespace: "airf", key: "related_pr") {
      value
    }
    descriptionTag: metafield(namespace: "global", key: "description_tag") {
      value
    }
    comp: metafield(namespace: "airf", key: "comp") {
      value
    }
    rrp_usd: metafield(namespace: "airf", key: "rrp_usd") {
      value
    }
    rrp_gbp: metafield(namespace: "airf", key: "rrp_gbp") {
      value
    }
    rrp_eur: metafield(namespace: "airf", key: "rrp_eur") {
      value
    }
    preorderMessage: metafield(namespace: "airf", key: "preorder_m") {
      value
    }
    variants(first: 100) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    title
    images(first: 20) {
      edges {
        node {
          originalSrc
          id
          altText
        }
      }
    }
    media(first: 20) {
      edges {
        node {
          mediaContentType
          alt
          ... on MediaImage {
            image {
              originalSrc
              id
            }
          }
          ... on Video {
            sources {
              url
              mimeType
              format
              height
              width
            }
          }
        }
      }
    }
  }
`
export const PRODUCT_FRAGMENT_SLIM = gql`
  ${VariantFragment}
  fragment ProductFragment on Product {
    id
    handle
    tags
    productType
    variants(first: 100) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    title
    images(first: 20) {
      edges {
        node {
          originalSrc
          id
          altText
        }
      }
    }
    media(first: 20) {
      edges {
        node {
          mediaContentType
          alt
          ... on MediaImage {
            image {
              originalSrc
              id
            }
          }
          ... on Video {
            sources {
              url
              mimeType
              format
              height
              width
            }
          }
        }
      }
    }
  }
`

export const PRODUCTS_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT_SLIM}
  fragment ProductsFragment on ProductConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...ProductFragment
      }
    }
  }
`
